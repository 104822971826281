import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { loginUser } from '../actions/authActions';
import '../styles/signinform.css';

const signInSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  password: z.string().min(3, { message: "Password is too short" }),
});

function Signinform() {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      signInSchema.parse(formData);
      dispatch(loginUser(formData, navigate));
    } catch (error) {
      if (error.name === 'ZodError') {
        error.errors.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="sign-in-container">
      <ToastContainer />
      <div className="sign-in-form">
        <h1 className="sign-in-title">Sign in</h1>
        <p className="sign-in-subtitle">Sign in with <span className="brand-name">K-Mart</span></p>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username..."
              value={formData.username}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              required
            />
          </div>

          <div className="input-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password..."
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
            />
          </div>

          <div className="options-group">
            <div className="checkbox-group">
              <input type="checkbox" id="save-id" />
              <label htmlFor="save-id">Save ID</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="keep-signed-in" />
              <label htmlFor="keep-signed-in">Keep me signed in</label>
            </div>
          </div>

          <button type="submit" className="sign-in-button">Sign in</button>

          <div className="additional-options">
            <a href="/" className="forgot-password">Forgot your password?</a>
            <p>Don't have an account? <Link to="/signup" className="forgot-password">Sign up</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signinform;
