import axios from 'axios';
import { API_URL } from '../variables';

// Action types
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_BY_ID_REQUEST = 'FETCH_PRODUCT_BY_ID_REQUEST';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILURE = 'FETCH_PRODUCT_BY_ID_FAILURE';

//Action types for size and option 

export const FETCH_PRODUCT_SIZES_REQUEST = 'FETCH_PRODUCT_SIZES_REQUEST';
export const FETCH_PRODUCT_SIZES_SUCCESS = 'FETCH_PRODUCT_SIZES_SUCCESS';
export const FETCH_PRODUCT_SIZES_FAILURE = 'FETCH_PRODUCT_SIZES_FAILURE';

export const FETCH_PRODUCT_OPTIONS_REQUEST = 'FETCH_PRODUCT_OPTIONS_REQUEST';
export const FETCH_PRODUCT_OPTIONS_SUCCESS = 'FETCH_PRODUCT_OPTIONS_SUCCESS';
export const FETCH_PRODUCT_OPTIONS_FAILURE = 'FETCH_PRODUCT_OPTIONS_FAILURE';

// Action to fetch all products
export const fetchProducts = () => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCTS_REQUEST });
  try {
    const response = await axios.post(`${API_URL}/api/products`);
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data.results });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: error.message });
  }
};

// Action to fetch a product by ID
export const fetchProductById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_BY_ID_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/${id}`);
    dispatch({ type: FETCH_PRODUCT_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_BY_ID_FAILURE, payload: error.message });
  }
};

// Action to fetch product sizes
export const fetchProductSizes = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_SIZES_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/sizes/${id}/`);
    dispatch({ type: FETCH_PRODUCT_SIZES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_SIZES_FAILURE, payload: error.message });
  }
};

// Action to fetch product options
export const fetchProductOptions = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_OPTIONS_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/sizes/${id}/`);
    dispatch({ type: FETCH_PRODUCT_OPTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_OPTIONS_FAILURE, payload: error.message });
  }
};


