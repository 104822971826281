import React from 'react';
import NewProducts from '../components/NewProducts';
import '../styles/new.css';

const New = () => {
  return (
    <div>
      <NewProducts />
    </div>
  );
};

export default New;
