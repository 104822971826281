import React, { useEffect } from 'react';  
import { useDispatch, useSelector } from 'react-redux'; 
import { useParams, useNavigate } from 'react-router-dom'; 
import { FaArrowLeft } from 'react-icons/fa'; 
import { getOrdersByUserId } from '../actions/ordersActions';  
import '../styles/orderdetails.css'; 

const OrderDetailsPage = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { orders = [], loading, error } = useSelector(state => state.orders);
    
    const order = orders.find(o => o.order_id === orderId); 

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin');
        } else if (user?.user_id) {
            dispatch(getOrdersByUserId(user.user_id));
        }
    }, [dispatch, isAuthenticated, navigate, user]);

    if (loading) {
        return <p className="order-details-loading-message">Loading order details...</p>;
    }

    if (error) {
        return <p className="order-details-error-message">{error}</p>;
    }

    if (!order) {
        return <p className="order-details-no-order-message">Order not found.</p>;
    }

    return (
        <div className="order-details-container">
            <button onClick={() => navigate('/orders')} className="order-details-back-button">
                <FaArrowLeft /> Orders
            </button>

            <h2 className="order-details-title">Order Details #{order.order_id}</h2>
            <p className="order-details-date">Date: {new Date(order.created_at).toLocaleDateString()}</p>

            <div className="order-details-status">
                <span>Status: </span>
                <span className={`order-details-status-text ${order.status.toLowerCase()}`}>
                    {order.status}
                </span>
            </div>

            <div className="order-details-summary">
                <h3>Order Summary</h3>
                <div className="order-details-summary-row">
                    <span>Product Total:</span>
                    <span>${Number(order.total_price).toFixed(2)}</span>
                </div>
                <div className="order-details-summary-row">
                    <span>Shipping Cost:</span>
                    <span>{order.shipping_cost || 'FREE'}</span>
                </div>
                <div className="order-details-summary-row total">
                    <span>Total:</span>
                    <span>${Number(order.total_price).toFixed(2)}</span>
                </div>
            </div>

            <div className="order-details-items-ordered">
                <h3>Items Ordered</h3>
                {Array.isArray(order.items) && order.items.length > 0 ? (  
                    order.items.map(item => (
                        <div key={item.id} className="order-details-ordered-item">
                            <img src={item.image} alt={item.name} className="order-details-item-image" />
                            <div className="order-details-item-details">
                                <p className="order-details-item-name">{item.name}</p>
                                <p className="order-details-item-quantity">{item.quantity}x</p>
                                <p className="order-details-item-price">${Number(item.price).toFixed(2)} USD</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No items found for this order.</p>
                )}
            </div>

            <div className="order-details-courier-info">
                <h3>Courier Information</h3>
                <p>Courier Name: {order.courier_name || 'Not Provided'}</p>
                <p>Tracking Number: {order.tracking_number || 'N/A'}</p>
                {order.tracking_url && (
                    <p>
                        <a href={order.tracking_url} target="_blank" rel="noopener noreferrer">
                            Track your shipment
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default OrderDetailsPage;
