import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../actions/ProductActions.js';
import { useNavigate } from 'react-router-dom';
import { useImage } from '../contexts/ImageContext';
import { FaCartPlus } from 'react-icons/fa'; 
import Loader from './Loader.jsx';
import '../styles/bestsellers.css';

const BestSellers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getImageUrl } = useImage();
  const { loading, products, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (loading) return <Loader />;  
  if (error) return <div>Error: {error}</div>;


  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="bestsellers-section">
      <h3 className="new-products-title">Best Sellers</h3>
      <div className="new-products-grid">
        {products.map((product) => (
          <div
            className="new-product-card"
            key={product.product_id}
            onClick={() => handleProductClick(product.product_id)}
          >
            <div
              className="new-product-image"
              style={{ backgroundImage: `url(${getImageUrl(product.image)})` }}
            >
              {product.tags.includes('Best') && (
                <span className="new-product-tag">BEST</span>
              )}
              <div className="new-product-overlay">
                <FaCartPlus className="new-product-icon" />
                <button className="new-product-shop-btn">Shop Now</button>
              </div>
            </div>
            <div className="new-product-details">
              <h3 className="new-product-name">{product.name}</h3>
              <p className="new-product-brand">{product.brand}</p>
              <div className="new-product-rating">
                {'⭐'.repeat(Math.floor(product.reviews[0]?.rating || 0))} {product.reviews[0]?.rating || 0}
              </div>
              {product.base_price && (
                <p className="new-product-old-price">{product.base_price} Rwf</p>
              )}
              <p className="new-product-price">{product.discounted_price} Rwf</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
