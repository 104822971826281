import React from 'react'
import Signinform from '../components/signinform'

function SignIn() {
  return (
        <div className='sign-in-from'>
            <Signinform/>
        </div>
  )
}

export default SignIn