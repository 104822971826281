import React, { useState, useEffect } from 'react';
import { FaUser, FaHeadset, FaSearch } from 'react-icons/fa';
import { MdLanguage } from 'react-icons/md';
import { FaCartShopping } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../variables';
import '../styles/Header.css';

const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/products/category/parent`);
        console.log('Fetched parent categories:', response.data);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm) {
      navigate(`/search?q=${trimmedSearchTerm}`);
      setSearchTerm('');
    }
  };

  const handleCategoryClick = async (categoryId) => {
    console.log('handleCategoryClick triggered for:', categoryId);
    try {
      const response = await axios.get(`${API_URL}/api/products/category/${categoryId}/`);
      console.log('Products for the selected category:', response.data);
      navigate(`/category/${categoryId}`);
    } catch (error) {
      console.error('Error fetching category products:', error);
    }
  };

  return (
    <header>
      <div className="container">
        <Link to="/" className="logo-container">
          <div className="logo">
            <span className="logo-text gray">K</span>
            <span className="logo-text green">-Mart</span>
          </div>
          <span className="korean-products">Korean Products</span>
        </Link>

        <form className="search-bar" onSubmit={handleSearch}>
          <div className="search-input-wrapper">
            <input
              type="text"
              className="search-input"
              placeholder="Search for a product or brand..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit" className="search-button">
              <FaSearch className="search-icon" />
            </button>
          </div>
        </form>

        <div className="icons-container">
          <Link to="/signin" className="icon-item">
            <FaUser className="icon" />
            <span>Sign in</span>
          </Link>
          <Link to="/cart" className="icon-item">
            <FaCartShopping className="icon" />
            <span>Cart</span>
          </Link>
          <div className="icon-item">
            <MdLanguage className="icon" />
            <span>Eng (USD)</span>
          </div>
          <div className="icon-item">
            <FaHeadset className="icon" />
            <span>Help</span>
          </div>
        </div>
      </div>

      <nav>
        <div className="nav-container">
          <div className="nav-items">
            <div className="nav-item dropdown">
              <button className="dropdown-button">CATEGORIES</button>
              <div className="dropdown-content">
                {Array.isArray(categories) && categories.length > 0 ? (
                  <div className="dropdown-column">
                    {categories.map((category) => (
                      <div key={category.category_id} className="parent-category">
                        <button
                          className="dropdown-column-title"
                          onClick={() => handleCategoryClick(category.category_id)}
                        >
                          {category.name}
                        </button>
                        {Array.isArray(category.children) && category.children.length > 0 && (
                          <div className="dropdown-subcategories">
                            {category.children.map((child) => (
                              <button
                                key={child.category_id}
                                className="subcategory"
                                onClick={() => handleCategoryClick(child.category_id)}
                              >
                                {child.name}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No categories available</div>
                )}
              </div>
            </div>
            <Link to="/best"><button>BESTSELLERS</button></Link>
            <Link to="/new"><button>NEW</button></Link>
            <Link to="/productspage"><button>SALE</button></Link>
            <button>BRANDS</button>
            <button>SKINCARE</button>
            <button>MAKEUP</button>
            <button>BODY & BATH</button>
            <button>HAIR</button>
            <button>FOOD & DRINK</button>
          </div>
          <div className="nav-items">
            <button>MEMBERSHIP</button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
