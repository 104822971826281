import React from 'react'
import BestSellers from '../components/BestSellers'

function Best() {
  return (
    <div>
   <BestSellers/> 
    </div>
  )
}

export default Best