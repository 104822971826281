import React from 'react';
import SearchResults from '../components/SearchResults';
import FilterSection from '../components/Filter';
import '../styles/search.css'; 

function Search() {
  return (
      <div className="search-container">
        <FilterSection />
        <SearchResults />
      </div>
  );
}

export default Search;
