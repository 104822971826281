import React from 'react';
import ImageSlider from '../components/ImageSlider';
import BestSellers from '../components/BestSellers';
import FeaturedBrands from '../components/FeaturedBrands';

function Home() {
  return (
    <div className="wrapper">
      <div className="content">
        <ImageSlider />
        <BestSellers />
      </div>
      <div className="brands">
        <FeaturedBrands/>
        </div>
    </div>
  );
}

export default Home;
