// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Best from './pages/Best';
import Search from './pages/Search';
import ProductView from './pages/ProductView';
import Layout from './components/Layout';
import SignUp from './pages/SignUp';
import Checkout from './pages/Checkout';
import Cart from './pages/Cart';
import New from './pages/New';
import ProductsPage from './pages/ProductsPage';
import OrdersPage from './pages/OrdersPage';
import OrderDetailsPage from './pages/OrderDetails';
import { rehydrateAuth } from './actions/authActions';
import { ImageProvider } from './contexts/ImageContext';
import Category from './pages/Category';
import TermsAndPoliciesPage from './pages/Terms';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rehydrateAuth());
  }, [dispatch]);

  return (
    <ImageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/category/:categoryId" element={<Layout><Category/></Layout>} />
          <Route path="/signin" element={<Layout><SignIn /></Layout>} />
          <Route path="/signup" element={<Layout><SignUp /></Layout>} />
          <Route path="/best" element={<Layout><Best /></Layout>} />
          <Route path="/new" element={<Layout><New /></Layout>} />
          <Route path="/productspage" element={<Layout><ProductsPage /></Layout>} />
          <Route path="/search" element={<Layout><Search /></Layout>} />
          <Route path="/product/:id" element={<Layout><ProductView /></Layout>} />
          <Route path="/checkout" element={<Layout><Checkout /></Layout>} />
          <Route path="/cart" element={<Layout><Cart /></Layout>} />
          <Route path="/orders" element={<Layout><OrdersPage/></Layout>} />
          <Route path="/terms" element={<Layout><TermsAndPoliciesPage/></Layout>} />
          <Route path="/orders/:orderId"  element={<Layout><OrderDetailsPage/></Layout>} />
        </Routes>
      </Router>
    </ImageProvider>
  );
};

export default App;
