import React, { useState, useEffect } from 'react';
import '../styles/checkout.css';

const ShippingInfo = ({ data, setData }) => {
  const [form, setForm] = useState(data || {});

  useEffect(() => {
    setForm(data);
  }, [data]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(form);
  };

  return (
    <form className="shippingInfoForm" onSubmit={handleSubmit}>
      <div>
        <label className="checkoutLabel">First Name *</label>
        <input
          className="checkoutInput"
          type="text"
          name="firstName"
          value={form.firstName || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label className="checkoutLabel">Last Name *</label>
        <input
          className="checkoutInput"
          type="text"
          name="lastName"
          value={form.lastName || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label className="checkoutLabel">Address *</label>
        <input
          className="checkoutInput"
          type="text"
          name="address"
          value={form.address || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label className="checkoutLabel">City *</label>
        <input
          className="checkoutInput"
          type="text"
          name="city"
          value={form.city || ''}
          onChange={handleChange}
          required
        />
      </div>
      <button className="checkoutButtonPrimary" type="submit">Save & Continue</button>
    </form>
  );
};

export default ShippingInfo;
