import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchProducts } from '../actions/ProductActions';
import { selectProducts } from '../selectors/ProductSelector';
import ProductCard from './ProductCard';
import Loader from './Loader';
import '../styles/searchresults.css';

const SearchResults = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const products = useSelector(selectProducts);
  const loading = useSelector((state) => state.products.loading);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Get the query from the URL
  const query = new URLSearchParams(location.search).get('q') || '';

  useEffect(() => {
    // Fetch products only once when the component mounts
    if (products.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, products.length]);
  useEffect(() => {
    if (query) {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase()) ||
      product.category.name.toLowerCase().includes(query.toLowerCase())
      );
      console.log('Filtered results for query:', query, results);
      setFilteredProducts(results);
    } else {
      setFilteredProducts(products);
    }
  }, [products, query]);
  

  return (
    <div className="search-results-container">
      {loading ? (
        <div className="loader-container">
          <Loader />
          <p>Loading products...</p>
        </div>
      ) : filteredProducts.length > 0 ? (
        <div className="products-grid">
          {filteredProducts.map((product) => (
            <ProductCard key={product.product_id} product={product} />
          ))}
        </div>
      ) : (
        <div className="no-results">
          <p>No results found for "{query}"</p>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
