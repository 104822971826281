import React from 'react'
import SignupForm from '../components/SignUpFrom';

function SignUp() {
  return (
    <div>
        <SignupForm/>
    </div>
  )
}

export default SignUp;