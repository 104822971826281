import axios from 'axios';
import { toast } from 'react-toastify';
import { setCartItems, addItemToCart, removeItemFromCart } from '../reducers/cartReducer';
import { API_URL } from '../variables';

export const fetchCart = (getImageUrl) => async (dispatch, getState) => {
  const { token , user} = getState().auth;  
  console.log('Auth state before fetching cart:', { token, user });             
  try {
    const response = await axios.post(`${API_URL}/api/orders/carts/get`,{user_id: user.user_id},  {
      headers: { Authorization: `${token}` },
    });
    const cartItems = response.data.cart_items.map((item) => ({
      cart_item_id: item.cart_item_id,
      product: {
        product_id: item.product.product_id,
        name: item.product.name,
        description: item.product.description || 'No description available', 
        price: parseFloat(item.price_at_purchase) || parseFloat(item.product.discounted_price) || parseFloat(item.product.base_price), 
        imageUrl: getImageUrl(item.product.image),
      },
      quantity: item.quantity,
    }));
    dispatch(setCartItems(cartItems));
  } catch (error) {
    console.error('Error fetching cart:', error);
  }
};

export const addToCart = (product) => async (dispatch, getState) => {
  const { token, user } = getState().auth;
  console.log('Auth state before adding to cart:', { token, user });
    try {
      const response = await axios.post(
        `${API_URL}/api/orders/carts/add`,
        {
          user_id: user.user_id,
          product_id: product.product_id,
          quantity: 1,
          option_id: product.option_id, 
          size_id: product.size_id,    
        },
        { headers: { Authorization: `${token}` } }
      );
          console.log(response.data);
          if (response.data.status === "success") {
            const cartItem = response.data.data;
      dispatch(addItemToCart(cartItem));
      toast.success('Item added to cart!');
      return cartItem;
    } else {
        console.error("Error adding to cart:", response.data.message);
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  export const removeFromCart = (cartItemId) => async (dispatch, getState) => {
    const { token } = getState().auth;
  
    if (!token) {
      console.error('Authorization token is missing.');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('cart_item_id', cartItemId);
  
      const response = await axios.post(
        `${API_URL}/api/orders/carts/remove`,
        formData, 
        { headers: { Authorization: `Bearer ${token}` } } // Ensure Bearer token is included
      );
  
      if (response.data.status === 'success') {
        dispatch(removeItemFromCart(cartItemId));
        toast.success('Item removed from cart!');
      } else {
        console.error('Error removing from cart:', response.data.message);
        toast.error('Failed to remove item from cart.');
      }
    } catch (error) {
      console.error('Error removing from cart:', error);
      toast.error('Failed to remove item from cart.');
    }
  };
  
  
