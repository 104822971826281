import React, { useState } from "react";
import "../styles/filtersection.css";
import { FaPlus, FaMinus } from "react-icons/fa";

const FilterSection = () => {
  const [activeCategory, setActiveCategory] = useState("Makeup Category");
  const [expandedSections, setExpandedSections] = useState([]);

  const toggleSection = (section) => {
    if (expandedSections.includes(section)) {
      setExpandedSections(expandedSections.filter((s) => s !== section));
    } else {
      setExpandedSections([...expandedSections, section]);
    }
  };

  const categories = [
    {
      name: "Makeup Category",
      options: ["Face", "Eye", "Lip", "Nail"],
    },
    {
      name: "Skin Concern",
      options: [
        "Brightening (5)",
        "Deep Cleansing (1)",
        "Dullness (16)",
        "Moisturising (188)",
        "Sculpting (5)",
        "Soothing (26)",
        "Sun Care (5)",
        "Well-aging (111)",
      ],
    },
    {
      name: "Skin Type",
      options: ["Oily", "Dry", "Combination"],
    },
  ];

  return (
    <div className="filter-section">
      <div className="filter-title">
      <h3>REFINE BY</h3>
      <p className="clear-all">Clear all</p>
      </div>

      {categories.map((category, index) => (
        <div key={index} className="filter-category">
          <div className="category-header" onClick={() => setActiveCategory(category.name)}>
            <h4>{category.name}</h4>
            {activeCategory === category.name || expandedSections.includes(category.name) ? (
              <FaMinus
                onClick={() => toggleSection(category.name)}
                className="toggle-icon"
              />
            ) : (
              <FaPlus
                onClick={() => toggleSection(category.name)}
                className="toggle-icon"
              />
            )}
          </div>

          {(activeCategory === category.name || expandedSections.includes(category.name)) && (
            <div className="category-options">
              {category.options.map((option, idx) => (
                <label key={idx}>
                  <input type="checkbox" />
                  {option}
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterSection;
