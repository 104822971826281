import React from 'react';
import FilterSection from '../components/Filter';
import ProductList from '../components/ProductList';
import '../styles/category.css'; 

function Category() {
  return (
      <div className='category-content'>
        <FilterSection />
        <ProductList />
      </div>
  );
}

export default Category;
