import {
    FETCH_REVIEWS_REQUEST,
    FETCH_REVIEWS_SUCCESS,
    FETCH_REVIEWS_FAILURE,
    SUBMIT_REVIEW_REQUEST,
    SUBMIT_REVIEW_SUCCESS,
    SUBMIT_REVIEW_FAILURE
  } from '../actions/reviewActions';
  
  const initialState = {
    reviews: [],
    loading: false,
    error: null
  };
  
  export const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REVIEWS_REQUEST:
        console.log("FETCH_REVIEWS_REQUEST received in reducer");
        return { ...state, loading: true, error: null };
      case FETCH_REVIEWS_SUCCESS:
        console.log("FETCH_REVIEWS_SUCCESS received in reducer", action.payload);
        return { ...state, loading: false, reviews: action.payload };
      case FETCH_REVIEWS_FAILURE:
        console.log("FETCH_REVIEWS_FAILURE received in reducer", action.payload);
        return { ...state, loading: false, error: action.payload };
      case SUBMIT_REVIEW_REQUEST:
        return { ...state, loading: true, error: null };
      case SUBMIT_REVIEW_SUCCESS:
        return { ...state, loading: false, reviews: [...state.reviews, action.payload] };
      case SUBMIT_REVIEW_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export default reviewReducer;