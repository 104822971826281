// ContactInfo.js
import React, { useState } from 'react';
import '../styles/checkout.css';

const ContactInfo = ({ data, setData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(form);
    // nextStep();
  };

  return (
    <form className="shippingInfoForm" onSubmit={handleSubmit}>
      <div>
        <label className="checkoutLabel">Email *</label>
        <input
          className="checkoutInput"
          type="email"
          name="email"
          value={form.email || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label className="checkoutLabel">Phone Number *</label>
        <input
          className="checkoutInput"
          type="tel"
          name="phone"
          value={form.phone || ''}
          onChange={handleChange}
          required
        />
      </div>
      <button className="checkoutButtonPrimary" type="submit">Save & Continue</button>
    </form>
  );
};

export default ContactInfo;
