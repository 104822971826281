import React from 'react';
import '../styles/loader.css';

const Loader = () => {
  return (
    <div className="loading-overlay">
      <div className="dots-loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <p className="loading-text">K-Mart is loading...</p>
    </div>
  );
};

export default Loader;
