import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import cartReducer from './reducers/cartReducer';
import productReducer from './reducers/ProductReducer';
import orderReducer from './reducers/orderReducer';
import reviewReducer from './reducers/reviewReducer';
const store = configureStore({
  reducer: {
    auth:authReducer,
    cart: cartReducer,
    products: productReducer,
    orders:orderReducer,
    review:reviewReducer
  },
});

export default store;
