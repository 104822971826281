import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { API_URL } from '../variables'; 
import '../styles/signinform.css'; 

const signUpSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  password: z.string().min(3, { message: "Password is too short" }),
  role: z.string().default("user"), 
  image: z.string().optional(), 
  phone: z.string().optional(), 
  address: z.string().optional(), 
});

function SignupForm() {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    password: '',
    role: 'user',
    image: '',
    phone: '',
    address: '',
  });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      signUpSchema.parse(formData);
      const response = await axios.post(`${API_URL}/api/users/createAccount`, formData);

      if (response.status === 200) {
        toast.success("Account created successfully!");
        setTimeout(() => {
          navigate('/signin'); 
        }, 2000);
      } else {
        toast.error(response.data.message || 'Something went wrong.');
      }
    } catch (error) {
      if (error.name === 'ZodError') {
        toast.error(error.errors[0].message);
      } else if (error.response) {
        toast.error(error.response.data.message || 'An error occurred. Please try again.');
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="sign-in-container">
      <ToastContainer />
      <div className="sign-in-form">
        <h1 className="sign-in-title">Sign up</h1>
        <p className="sign-in-subtitle">Create your account at <span className="brand-name">K-Mart</span></p>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username..."
              value={formData.username}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name..."
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email..."
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password..."
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone (optional)..."
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Address (optional)..."
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            />
          </div>

          <button type="submit" className="sign-in-button">Sign up</button>

          <div className="additional-options">
            <p>Already have an account? <Link to="/signin" className="forgot-password">Sign in</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
