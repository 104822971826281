import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../styles/productlist.css";
import ProductCard from "./ProductCard";
import Loader from "./Loader";
import { API_URL } from '../variables';

const ProductList = () => {
  const { categoryId } = useParams();  
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(''); 

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/products/category/${categoryId}/`);
        setProducts(response.data.products || []);
        setCategoryName(response.data.name || ''); 
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]); 
      } finally {
        setLoading(false);
      }
    };
    
    if (categoryId) {
      fetchProductsByCategory();
    }
  }, [categoryId]);  

  return (
    <div className="products">
      <div className="list-header">
        <div className="list-header-title">
          <h2>{categoryName || 'Products'}</h2> 
        </div>
        <div className="sort-options">
          <div>
            <span>{products.length || 0} items</span>
            <span>|</span>
            <span>view 24 36 48</span>
          </div>
          <select>
            <option value="new">New</option>
            <option value="best">Best</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <Loader />
          <p>Loading products...</p>
        </div>
      ) : products.length > 0 ? (
        <div className="products-grid">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      ) : (
        <div className="no-products">
          <p>No products available</p>
        </div>
      )}
    </div>
  );
};

export default ProductList;
