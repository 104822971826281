import React, { useEffect } from 'react';  
import { useDispatch, useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom'; 
import OrderItem from '../components/OrderItem'; 
import OrderSummary from '../components/Ordersummary'; 
import { getOrdersByUserId } from '../actions/ordersActions';  
import '../styles/orders.css';   

const OrdersPage = () => {     
    const dispatch = useDispatch();     
    const navigate = useNavigate();     
    const { isAuthenticated, user } = useSelector(state => state.auth);     
    const { orders = [], loading, error } = useSelector(state => state.orders);      

    useEffect(() => {         
        if (!isAuthenticated) {             
            navigate('/signin');         
        } else if (user?.user_id) {             
            dispatch(getOrdersByUserId(user.user_id));          
        }     
    }, [dispatch, isAuthenticated, navigate, user]);      

    const handleOrderClick = (orderId) => {
        navigate(`/orders/${orderId}`); // Navigate to Order Details page
    };

    return (         
        <div className="orders-container">             
            <h2 className="orders-title">My Orders</h2>             
            {loading && <p className="loading-message">Loading your orders...</p>}             
            {error && <p className="error-message">{error}</p>}             
            {orders.length === 0 && !loading && <p className="no-orders-message">You have no orders.</p>}                          
            
            <div className="orders-list">                 
                {orders.map(order => (                     
                    <div key={order.order_id} onClick={() => handleOrderClick(order.order_id)}>
                        <OrderItem                         
                            order={{                             
                                orderId: order.order_id,                             
                                status: order.status,                             
                                totalPrice: order.total_price,                             
                                createdAt: order.created_at,                             
                                paymentStatus: order.payment_status,                             
                                address: order.address || 'No address provided',                          
                                totalProducts: order.total_products,                         
                            }}                     
                        />   
                    </div>                 
                ))}              
            </div>                          
            
            {orders.length > 0 && <OrderSummary orders={orders} />}         
        </div>     
    ); 
};  

export default OrdersPage;
