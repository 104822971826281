import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import '../styles/terms-policies.css';

function TermsAndPoliciesPage() {
  const navigate = useNavigate();

  return (
    <div className="tp-container">
      <button onClick={() => navigate('/')} className="tp-back-button">
        <FaArrowLeft /> Back to Home
      </button>

      <h1 className="tp-main-title">Terms and Policies</h1>

      <div className="tp-section">
        <h2 className="tp-section-title">1. Terms of Service</h2>
        <div className="tp-section-content">
          <p>Users agree to comply with the terms when using the website. The company provides 24/7 service but may suspend it for maintenance or emergencies. Users are responsible for managing their account information.</p>
          <p>Prohibited activities include:</p>
          <ul>
            <li>Unauthorized use of the service</li>
            <li>Hacking or attempting to breach security</li>
            <li>Spreading viruses or malicious code</li>
            <li>Violating intellectual property rights</li>
          </ul>
        </div>
      </div>

      <div className="tp-section">
        <h2 className="tp-section-title">2. Privacy Policy</h2>
        <div className="tp-section-content">
          <p>The company collects minimal personal information necessary to provide services. Information is collected legally and with user consent. Users can refuse to receive marketing emails.</p>
          <p>Key points:</p>
          <ul>
            <li>Personal information is protected and not shared without consent</li>
            <li>Users have the right to access and correct their personal information</li>
            <li>The company uses security measures to protect user data</li>
          </ul>
        </div>
      </div>

      <div className="tp-section">
        <h2 className="tp-section-title">3. Refund Policy</h2>
        <div className="tp-section-content">
          <p>Our refund policy includes:</p>
          <ul>
            <li>Users can cancel orders within 7 days of purchase in most cases</li>
            <li>Some items like opened/used products may not be eligible for return</li>
            <li>The company will process refunds within 3 business days of receiving returned items</li>
            <li>Users are responsible for return shipping costs unless the item was defective</li>
          </ul>
        </div>
      </div>

      <div className="tp-section">
        <h2 className="tp-section-title">4. International Shipping</h2>
        <div className="tp-section-content">
          <p>For international orders:</p>
          <ul>
            <li>Customs duties are the responsibility of the recipient</li>
            <li>Delivery times may be delayed due to customs processing</li>
            <li>Returns for international orders have special procedures</li>
          </ul>
        </div>
      </div>

      <div className="tp-section">
        <h2 className="tp-section-title">5. Reward Points</h2>
        <div className="tp-section-content">
          <p>Our reward points system:</p>
          <ul>
            <li>Points can be earned on purchases and used like cash on the site</li>
            <li>Points expire after 2 years if unused</li>
            <li>Points cannot be redeemed for cash</li>
          </ul>
        </div>
      </div>

      <div className="tp-section">
        <h2 className="tp-section-title">6. Liability</h2>
        <div className="tp-section-content">
          <p>Liability limitations:</p>
          <ul>
            <li>The company is not liable for damages caused by user negligence or force majeure events</li>
            <li>Disputes will be governed by the laws of South Korea</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TermsAndPoliciesPage;
