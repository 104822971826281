import React from 'react';
import image1 from '../assets/images/brands1.png';
import image2 from '../assets/images/brands6.jpg';
import image3 from '../assets/images/brands5.jpg';
import image4 from '../assets/images/brands4.jpg';
import '../styles/featuredbrands.css';

function FeaturedBrands() {
  return (
    <div className="featured-brands">
      <h2 className="title">FEATURED BRANDS</h2>
      <p className="description">haircare, makeup, skincare + fragrance for all.</p>
      
      <div className="brands-grid">
        <div className="brand-item">
          <img src={image1} alt="Fenty Hair" className="brand-image" />
          <p className="brand-name">OLIVE YOUNG</p>
        </div>
        
        <div className="brand-item">
          <img src={image2} alt="Fenty Beauty" className="brand-image" />
          <p className="brand-name">AESTURA</p>
        </div>
        
        <div className="brand-item">
          <img src={image3} alt="Fenty Skin" className="brand-image" />
          <p className="brand-name">ANUA</p>
        </div>
        
        <div className="brand-item">
          <img src={image4} alt="Fenty Fragrance" className="brand-image" />
          <p className="brand-name">CORX</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturedBrands;
