import React, { useState } from 'react';
import '../styles/product.css';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../actions/cartActions';
import { useNavigate, useParams  } from 'react-router-dom';
import CartModal from './CartModal';
import { useImage } from "../contexts/ImageContext";
import ReviewPage from './Review';


const Product = ({ product }) => {
  const { productId } = useParams(); 
  const [activeSection, setActiveSection] = useState(null);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addedItem, setAddedItem] = useState(null);
  const navigate = useNavigate();
  const { getImageUrl } = useImage();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };
    
  const {
    name,
    base_price,
    discounted_price,
    description,
    features = [],
    image,
    other_images = [],
    product_info = [],
    specific_info = [],
    tags = [],
    reviews = [],
    product_rating = 0,
  } = product;
  
  const otherImageUrls = other_images.map(img => `/path_to_images/${img}`);
  const discount = base_price !== discounted_price ? Math.round((1 - (discounted_price / base_price)) * 100) : null;

  const openCartModal = (item) => {
    setAddedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddToCart = () => {
    if (!isAuthenticated) {
      navigate('/signin');
    } else {
      dispatch(addToCart(product)).then((response) => {
        if (response && response.data && response.data.status === "success") {
          openCartModal(response.data.data); 
        }
      }).catch(error => {
        console.error("Error adding to cart:", error);
      });
    }
  };
  

  return (
    <div className="product-view-container">
      <div className="product-view">
        <div className="product-image-section">
          <img src={getImageUrl(image)} alt={name} className="product-image" />
          {otherImageUrls.length > 0 && (
            <div className="additional-images">
              {otherImageUrls.map((url, index) => (
                <img key={index} src={url} alt={`${name} - ${index + 1}`} className="other-product-image" />
              ))}
            </div>
          )}
        </div>

        <div className="product-details-section">
          <div className="product-tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
          </div>

          <h1 className="product-title">{name}</h1>

          <div className="product-rating">
            <span className="stars">★★★★★</span>
            <span className="reviews">({product_rating} Reviews)</span>
          </div>

          <div className="product-price-section">
            <span className="discounted-price">${discounted_price}</span>
            {discount && (
              <>
                <span className="original-price">${base_price}</span>
                <span className="discount">{discount}% Off</span>
              </>
            )}
          </div>

          <p className="delivery-info">We deliver to RWANDA</p>
          <p className="shipping-info">Free shipping available from USD $60</p>

          <div className="product-options">
            <label htmlFor="product-size">Select Type</label>
            <select id="product-size" className="product-size-dropdown">
              <option value="default">Default Option</option>
            </select>
          </div>

          <div className="product-quantity">
            <button className="quantity-btn">-</button>
            <input type="number" defaultValue={1} className="quantity-input" />
            <button className="quantity-btn">+</button>
          </div>

          <button className="add-to-cart-btn" onClick={handleAddToCart}>
            Add to Cart - USD {discounted_price}
          </button>
        </div>
      </div>

      <div className="product-info-section">
        <div className="faq-item" onClick={() => toggleSection('info')}>
          <h2>Product Info</h2>
          <span className={`chevron ${activeSection === 'info' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'info' && (
          <div className="faq-content">
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {specific_info.length > 0 && (
              <ul>
                {specific_info.map((info, index) => (
                  <li key={index}><strong>{info.label}:</strong> {info.value}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('love')}>
          <h2>Why We Love It</h2>
          <span className={`chevron ${activeSection === 'love' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'love' && (
          <div className="faq-content">
            {product_info.length > 0 ? (
              product_info.map((info, index) => (
                <p key={index}>{info.value}</p>
              ))
            ) : (
              <p>No additional information provided.</p>
            )}
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('features')}>
          <h2>Features</h2>
          <span className={`chevron ${activeSection === 'features' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'features' && (
          <div className="faq-content">
            <ul>
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="faq-item" onClick={() => toggleSection('howToUse')}>
          <h2>How to Use</h2>
          <span className={`chevron ${activeSection === 'howToUse' ? 'rotate' : ''}`}>
            <FaChevronDown />
          </span>
        </div>
        {activeSection === 'howToUse' && (
          <div className="faq-content">
            <p>Instructions on how to use the product.</p>
          </div>
        )}
      </div>
      {/* <div className="faq-item" onClick={() => toggleSection('reviews')}>
  <h2>Reviews</h2>
  <span className={`chevron ${activeSection === 'reviews' ? 'rotate' : ''}`}>
    <FaChevronDown />
  </span>
</div>
{activeSection === 'reviews' && (
  <div className="faq-content">
    <ReviewPage reviews={reviews} productId={productId} /> 
  </div>
)} */}

      <CartModal isOpen={isModalOpen} closeModal={closeModal} cartItem={addedItem} />
    </div>
  );
};
export default Product;
