import React from 'react';
import { FaCartPlus } from 'react-icons/fa';
import image1 from '../assets/images/oliveyoung1.png';
import image2 from '../assets/images/nails.jpg';
import image3 from '../assets/images/makeup1.jpg';
import image4 from '../assets/images/makeup2.jpg';
import '../styles/new.css';

const products = [
  {
    tag: 'HOT DEAL',
    backgroundImage: image1,
    name: 'Anua Niacinamide 10% +TXA 4% Dark Spot Correcting Serum 30ml',
    brand: 'Anua',
    rating: 4.6,
    price: '20,000 RWF',
    // oldPrice: '20,000 RWF',
    deal: 'Save More with Coupon',
    gift: 'Gift with Purchase',
  },
  {
    tag: 'BEST',
    backgroundImage: image2,
    name: 'HERA Sensual Nude Gloss 5g',
    brand: 'HERA',
    rating: 4.8,
    price: '20,000 RWF',
    oldPrice: '20,000 RWF',
    gift: 'Gift with Purchase',
  },
  {
    tag: 'BEST',
    backgroundImage: image3,
    name: '[Disney Edition] MEDIHEAL Madecassoside Blemish Pad 100P (+100P)',
    brand: 'MEDIHEAL',
    rating: 4.8,
    price: '20,000 RWF',
    oldPrice: '20,000 RWF',
    gift: 'Gift with Purchase',
  },
  {
    tag: 'BEST',
    backgroundImage: image4,
    name: 'iUNIK Centella Calming Gel Cream Double Set',
    brand: 'iUNIK',
    rating: 4.6,
    price: '20,000 RWF',
    gift: 'Gift with Purchase',
  },
];

const NewProducts = () => {
  return (
    <div className="new-products-container">
      <h2 className="new-products-title">New Products</h2>
      <div className="new-products-grid">
        {products.map((product, index) => (
          <div key={index} className="new-product-card">
            <div
              className="new-product-image"
              style={{ backgroundImage: `url(${product.backgroundImage})` }}
            >
              {product.tag && <span className="new-product-tag">{product.tag}</span>}
              <div className="new-product-overlay">
                <FaCartPlus className="new-product-icon" />
                <button className="new-product-shop-btn">Shop Now</button>
              </div>
            </div>
            <div className="new-product-details">
              <h3 className="new-product-name">{product.name}</h3>
              <p className="new-product-brand">{product.brand}</p>
              <div className="new-product-rating">
                {'⭐'.repeat(Math.floor(product.rating))} {product.rating}
              </div>
              {product.oldPrice && (
                <p className="new-product-old-price">{product.oldPrice}</p>
              )}
              <p className="new-product-price">{product.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewProducts;
