import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 
import '../styles/imageslider.css'; 
import image1 from '../assets/images/slider1.jpg';
import image2 from '../assets/images/slider2.jpg';
import image3 from '../assets/images/slider3.jpg';
import image4 from '../assets/images/slider4.jpg';
import image5 from '../assets/images/oliveyoung1.png';
import image6 from '../assets/images/oliveyoung2.jpg';
import image7 from '../assets/images/makeup2.jpg';

const images = [image1, image2, image3, image4, image5, image6, image7]; 

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, 2000); 

    return () => clearInterval(interval);
  }, [isHovered]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleShopNowClick = () => {
    navigate("/productspage"); // Navigate to productspage 
  };

  return (
    <div
      className="slider"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Slide container with background image */}
      <div
        className="slide"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      >
        {/* Text and Button Overlay */}
        <div className="slider-content">
          <h2>Discover the Latest Trends</h2>
          <p>Find your perfect beauty products now.</p>
          <button className="shop-now-btn" onClick={handleShopNowClick}>
            Shop Now
          </button>
        </div>
      </div>

      {/* Slider controls */}
      <div className="slider-controls">
        <div className="left-arrow" onClick={goToPrevious}>
          ❰
        </div>
        <div className="slider-indicators">
          {images.map((_, index) => (
            <div
              key={index}
              className={`indicator ${index === currentIndex ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
        <div className="right-arrow" onClick={goToNext}>
          ❱
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
