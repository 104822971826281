import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/orders.css';

const OrderItem = ({ order }) => {
    return (
        <Link to={`/orders/${order.orderId}`} className="order-item-link">
            <div className="order-item">
                <h3 className="order-id">Order ID: {order.orderId}</h3>
                <p className="order-status">Status: {order.status}</p>
                <p className="order-total-price">
                    Total Price: ${Number(order.totalPrice).toFixed(2)}
                </p>
                <p className="order-date">Date: {new Date(order.createdAt).toLocaleDateString()}</p>
                <p className="payment-status">Payment Status: {order.paymentStatus}</p>
                <p className="order-address">Address: {order.address}</p>
                <p className="total-products">Total Products: {order.totalProducts}</p>
            </div>
        </Link>
    );
};

export default OrderItem;
