import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/ordersummary.css";

const OrderSummary = ({ cartTotal = 0 }) => {
  // default value for cartTotal
  const location = useLocation();
  const isCartPage = location.pathname === "/cart";

  return (
    <div className="order-summary">
      <h4>Order Summary</h4>
      <div className="summary-detail">
        <span>Subtotal:</span>
        <span>${cartTotal.toFixed(2)}</span>
      </div>
      <div className="summary-detail">
        <span>Tax:</span>
        <span>${(cartTotal * 0.15).toFixed(2)}</span>
      </div>
      <div className="summary-total">
        <strong>Total:</strong>
        <strong>${(cartTotal * 1.15).toFixed(2)}</strong>
      </div>
      {isCartPage && (
        <Link to="/checkout">
          <button className="checkout-button">Proceed to Checkout</button>
        </Link>
      )}
    </div>
  );
};

export default OrderSummary;
