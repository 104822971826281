import React from "react";
import { FaHeart, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { removeFromCart } from "../actions/cartActions"; 
import "../styles/cartitem.css";

const CartItem = ({ product }) => {
  const dispatch = useDispatch();

  const handleRemoveClick = () => {
    dispatch(removeFromCart(product.product_id)); 
  };

  return (
    <div className="cart-item">
      <img src={product.imageUrl} alt={product.name} className="cart-item-image" />
      <div className="cart-item-details">
        <h4 className="cart-item-name">{product.name}</h4>
        <p className="cart-item-description">{product.description}</p>
        <div className="cart-item-price-section">
          <span className="cart-item-price">USD {product.price}</span>
          {product.discountedPrice && (
            <span className="cart-item-discounted-price">USD {product.discountedPrice.toFixed(2)}</span>
          )}
        </div>
        <div className="cart-item-actions">
          <div className="cart-item-quantity-controls">
            <button className="quantity-decrease">-</button>
            <span className="cart-item-quantity">{product.quantity}</span>
            <button className="quantity-increase">+</button>
          </div>
          <button className="edit-button">Edit</button>
        </div>
      </div>
      <div className="cart-item-icons">
        <FaHeart className="icon-heart green" />
        <FaTimes className="icon-remove gray" onClick={handleRemoveClick} /> 
      </div>
    </div>
  );
};

export default CartItem;
