// Checkout.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContactInfo from '../components/ContactInfo';
import ShippingInfo from '../components/ShippingInfo';
import OrderSummary from '../components/Ordersummary';
import { fetchCart } from '../actions/cartActions';
import { placeOrder } from '../actions/ordersActions';
import { useNavigate } from 'react-router-dom';
import '../styles/checkout.css';

const Checkout = () => {
  const [shippingData, setShippingData] = useState({});
  const [contactData, setContactData] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  const dispatch = useDispatch();
  const { items: cartItems = [] } = useSelector((state) => state.cart);
  const cartTotal = cartItems.reduce(
    (total, item) => total + (parseFloat(item.product.price) || 0) * (item.quantity || 1),
    0
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCart());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const address = `${shippingData.firstName} ${shippingData.lastName}, ${shippingData.address}, ${shippingData.city}, ${shippingData.zipCode}, ${shippingData.country}`;
    const orderData = {
      address,
      contactInfo: contactData,
      cartItems,
    };

    try {
      await dispatch(placeOrder(orderData));
      navigate('/orders'); // Navigate to the orders page after placing the order
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const toggleSection = (section) => {
    setActiveSection((prevSection) => (prevSection === section ? null : section));
  };

  const isSectionFilled = (data) => {
    return Object.keys(data).length > 0;
  };

  return (
    <div className="checkoutPageContainer">
      <form onSubmit={handleSubmit} className="formSectionContainer">
        {/* Shipping Information */}
        <div className="formSection checkout-shipping">
          <div className="checkout-section-header" onClick={() => toggleSection('shipping')}>
            <div className="checkout-header">
              <h2 className="checkout-heading">Shipping Information</h2>
              <span className="checkout-section-toggle">
                {activeSection === 'shipping' ? '-' : '+'}
              </span>
            </div>
            {isSectionFilled(shippingData) && (
              <p className="section-summary">
                {shippingData.firstName} {shippingData.lastName}, {shippingData.address}, {shippingData.city}, {shippingData.zipCode}, {shippingData.country}
              </p>
            )}
          </div>
          {activeSection === 'shipping' && (
            <div className="shippingInfoForm">
              <ShippingInfo data={shippingData} setData={setShippingData} />
            </div>
          )}
        </div>

        {/* Contact Information */}
        <div className="formSection checkout-contact">
          <div className="checkout-section-header" onClick={() => toggleSection('contact')}>
            <div className="checkout-header">
              <h2 className="checkout-heading">Contact Information</h2>
              <span className="checkout-section-toggle">
                {activeSection === 'contact' ? '-' : '+'}
              </span>
            </div>
            {isSectionFilled(contactData) && (
              <p className="section-summary">
                {contactData.email}, {contactData.phone}
              </p>
            )}
          </div>
          {activeSection === 'contact' && (
            <div className="contactInfoForm">
              <ContactInfo data={contactData} setData={setContactData} />
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" className="placeorder-submitButton">Place Order</button>
      </form>

      {/* Order Summary */}
      <div className="orderSummarySection">
        <OrderSummary cartTotal={cartTotal} />
      </div>
    </div>
  );
};

export default Checkout;
