import axios from 'axios';
import { API_URL } from '../variables';

// Constants
export const SUBMIT_REVIEW_REQUEST = 'SUBMIT_REVIEW_REQUEST';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILURE = 'SUBMIT_REVIEW_FAILURE';
export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';

// Action to submit a review
export const submitReview = (reviewData, productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBMIT_REVIEW_REQUEST });
    const { token, user } = getState().auth;

    if (!user || !token) {
      throw new Error('User must be authenticated to submit a review');
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${token}`,
      }
    };

    const formData = new FormData();
    formData.append('product_id', productId); // Add product_id
    formData.append('userId', user.id);
    formData.append('rating', reviewData.rating);
    formData.append('comment', reviewData.comment);

    const { data } = await axios.post(`${API_URL}/api/products/reviews/add`, formData, config);
    
    dispatch({ type: SUBMIT_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUBMIT_REVIEW_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Action to fetch reviews for a specific product
export const fetchReviews = (productId) => async (dispatch) => {
  dispatch({ type: FETCH_REVIEWS_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/api/products/reviews/${productId}`);
    dispatch({ type: FETCH_REVIEWS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_REVIEWS_FAILURE, payload: error.message });
  }
}