import axios from 'axios';
import { toast } from 'react-toastify';
import { setAuthData, clearAuthData } from '../reducers/authReducer';
import { API_URL } from '../variables';

export const loginUser = (formData, navigate) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/login`, formData);

    if (response.status === 200) {
      const { token, user } = response.data;
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', user.user_id); 
      dispatch(setAuthData({ user, token }));
      console.log('User after login:', user);
      toast.success('Login successful!');
      navigate('/'); 
    } else {
      toast.error(response.data.message || 'Login failed.');
    }
  } catch (error) {
    toast.error('Invalid username or password.');
  }
};

export const rehydrateAuth = () => (dispatch) => {
  const token = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');  // Retrieve userId

  if (token && userId) {
    dispatch(setAuthData({ token, user: { user_id: userId } })); // Rehydrate with user_id only
  } else {
    dispatch(clearAuthData());
  }
};
export const logoutUser = (navigate) => (dispatch) => {
  localStorage.removeItem('authToken');
  dispatch(clearAuthData());
  toast.success('Logged out successfully!');
  navigate('/signin'); 
};
