import axios from 'axios';
import { API_URL } from '../variables';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

export const placeOrder = (orderData) => async (dispatch, getState) => {
    dispatch({ type: PLACE_ORDER_REQUEST });

    const { token } = getState().auth; 

    try {
        const response = await axios.post(`${API_URL}/api/orders/place`, orderData, {
            headers: {
                Authorization: `${token}`,  
            },
        });
        dispatch({ type: PLACE_ORDER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: PLACE_ORDER_FAILURE, payload: error.message });
    }
};

// Get Orders by User ID
export const getOrdersByUserId = () => async (dispatch, getState) => {
    dispatch({ type: GET_ORDERS_REQUEST });

    const { user, token } = getState().auth;

    try {
        const response = await axios.post(
            `${API_URL}/api/orders/user`, 
            { user_id: user.user_id }, 
            {
                headers: { Authorization: `${token}` },
            }
        );
        console.log('Fetched orders response:', response.data); // Add this log

        dispatch({ type: GET_ORDERS_SUCCESS, payload: response.data.orders || response.data});
    } catch (error) {
        console.error('Error fetching orders:', error);
        dispatch({ type: GET_ORDERS_FAILURE, payload: error.message });
    }
};


// Update Order Status
export const updateOrderStatus = (orderData) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_ORDER_STATUS_REQUEST });

    const { token } = getState().auth;

    try {
        const response = await axios.post(`${API_URL}/api/orders/update`, orderData, {
            headers: {
                Authorization: `${token}`,
            },
        });
        dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: UPDATE_ORDER_STATUS_FAILURE, payload: error.message });
    }
};

// Cancel Order
export const cancelOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: CANCEL_ORDER_REQUEST });

    const { token } = getState().auth;

    try {
        const response = await axios.post(`${API_URL}/api/orders/cancel`, { order_id: orderId }, {
            headers: {
                Authorization: `${token}`,
            },
        });
        dispatch({ type: CANCEL_ORDER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: CANCEL_ORDER_FAILURE, payload: error.message });
    }
};
