import React from 'react';
import { Link } from 'react-router-dom'; 
import '../styles/Footer.css'; 

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer'>
        <div>
          <h3>MY ACCOUNT</h3>
          <p><Link to="/orders">Track orders</Link></p> 
          <p>Wishlist</p>
          <p>Contact us</p>
        </div>
        <div className="about">
          <h3>ABOUT US</h3>
          <p>K-Mart is a Korean company based in Rwanda </p>
          <p>By building strong human and material exchange channels between Korea and Rwanda, we aim to contribute to the continued revitalization of solid mutual trust and broader and deeper economic exchanges.</p>
        </div>
        <div>
          <h3>HELP</h3>
          <p>Customer service</p>
          <p>FAQS</p>
        </div>
        <div>
          <h3>TERMS & POLICIES</h3>
          <p><Link to="/terms">Terms & Conditions</Link></p>
          <p>Legal notices</p>
          <p><Link to="/terms">Privacy policy</Link></p> 
        </div>
      </div>
      <p className="copyright">Copyright © 2021 K-Mart. All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
