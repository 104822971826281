import React from "react";
import '../styles/new.css'; 
import { FaCartPlus } from "react-icons/fa";
import { useImage } from '../contexts/ImageContext';

const ProductCard = ({ product }) => {
  const { getImageUrl } = useImage();

  return (
    <div className="new-product-card">
      <div className="new-product-tag">
        {product.tags.includes('Best') ? 'BEST' : 'NEW'}
      </div>
      <div className="new-product-image">
        <img
          src={getImageUrl(product.image)}
          alt={product.name}
          className="product-image"
        />
      </div>
      <div className="new-product-details">
        <h2 className="new-product-brand">{product.brand}</h2>
        <p className="new-product-name">{product.name}</p>
        <div className="price-section">
          {product.base_price && (
            <span className="new-product-old-price">{product.base_price} Rwf</span>
          )}
          <span className="new-product-price">{product.discounted_price} Rwf</span>
        </div>
        <div className="new-product-overlay">
          <div className="new-product-icon">
            <FaCartPlus />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
